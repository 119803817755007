import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

import useLocale from '@shared/hooks/locale'
import { updateCurrentUserPrivacyPolicy } from '@shared/http/api'

export default function usePrivacyPolicy() {
  const { getAttributeTranslation } = useLocale()
  const store = useStore()
  const { t } = useI18n()
  const loading = ref(false)

  const l10nContent = computed(() => {
    if (!store.state.terms.privacyPolicy) {
      return t('ttmt.privacy_policies.errors.not_found')
    }

    const content = getAttributeTranslation(store.state.terms.privacyPolicy.attributes.content)

    return DOMPurify.sanitize(
      marked(content ?? ''),
      {},
    )
  })

  function updatePrivacyPolicy() {
    loading.value = true

    const params = {
      data: {
        version: store.state.terms.privacyPolicy.attributes.version,
      },
    }

    updateCurrentUserPrivacyPolicy(params)
      .then((response) => {
        store.commit(
          'auth/SET_USER',
          response.data.data,
        )

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.privacy_policies.update.success'),
            type: 'success',
          },
        )
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    l10nContent,
    updatePrivacyPolicy,
    loading,
  }
}
