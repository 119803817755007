<template>
  <router-view />

  <div
    class="text-center mt-14"
  >
    <p>
      {{ t('ttmt.auth.messages.professional') }}
      <a
        class="app-link"
        :href="proUrl"
      >
        {{ t('ttmt.auth.messages.extranet_here') }}
      </a>
    </p>
  </div>

  <app-locale-selector
    class="mt-3 w-52 h-11 mx-auto"
    :options="staticLocalesOptionsTranslated"
    :initial-value="shortLocale"
    open-direction="top"
    @changed="handleLocaleChange"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppLocaleSelector from '@shared/components/ui/AppLocaleSelector.vue'

const { t } = useI18n()
const {
  staticLocalesOptionsTranslated,
  shortLocale,
  handleChange: handleLocaleChange,
} = useLocale()

const proUrl = computed(() => (
  import.meta.env.VITE_PRO_URL
))
</script>
