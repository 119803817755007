<template>
  <div
    v-if="success && !store.state.spinner.active"
  >
    <app-heading class="mb-4">
      {{ t('ttmt.user_credential_verifications.verify.sent.title') }}
    </app-heading>

    <p class="mb-2">
      {{ t('ttmt.user_credential_verifications.verify.sent.email_1', { email: credentialSent }) }}
    </p>

    <p class="mb-3">
      {{ t('ttmt.user_credential_verifications.verify.sent.email_2') }}
    </p>

    <app-button
      emphasis="low"
      :label="t('ttmt.user_credential_verifications.verify.sent.again')"
      whitespace-classes="whitespace-normal"
      :loading="formSubmitting"
      width="full"
      class="mb-3"
      @click="sendCredentialVerification(credentialSent)"
    />

    <app-button
      emphasis="low"
      :label="t('ttmt.user_credential_verifications.verify.sent.correct')"
      whitespace-classes="whitespace-normal"
      width="full"
      @click="resetForm"
    />
  </div>

  <div
    v-else-if="!store.state.spinner.active"
  >
    <p class="text-xs mb-3">
      {{ t('ttmt.auth.credential_verification.intro') }}
    </p>

    <!-- Verification form -->
    <form
      novalidate
      @submit.prevent="sendCredentialVerification(values.credential)"
    >
      <form-group
        rules="required|credential"
        name="credential"
        type="email"
        :placeholder="t('ttmt.form.labels.user.credential')"
        :label="false"
        width="full"
        :initial-value="credential"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <app-button
        type="submit"
        width="full"
        :disabled="formSubmitting"
        :loading="formSubmitting"
        feature="confirm"
      />
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useForm } from 'vee-validate'

import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()
const store = useStore()
const route = useRoute()

// ---------- FORM ----------

const { values, setErrors } = useForm()
const formSubmitting = ref(false)

// Reset the initial form state
function resetForm() {
  success.value = false
  credentialSent.value = null
}

// ---------- CREDENTIAL ----------

const credential = route.query.credential

// Value of the credential sent for verification
const credentialSent = ref()
const success = ref(false)

function sendCredentialVerification(value) {
  credentialSent.value = value
  formSubmitting.value = true

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes: {
        credential: value,
      },
    },
  }).then(() => {
    success.value = true
  }).catch((e) => {
    if (e.response?.data?.errors) {
      setErrors({ ...e.response.data.errors })
    }
    resetForm()
  }).finally(() => {
    formSubmitting.value = false
  })
}
</script>
