<template>
  <auth-header />

  <auth-title
    :text="t('ttmt.auth.messages.register_title')"
  />

  <div
    v-if="success"
  >
    <p class="mb-4">
      {{ t('ttmt.auth.register.success.title') }}
    </p>

    <p class="font-bold mb-2">
      {{ t('ttmt.auth.register.success.email_1', { email: form.values.credential }) }}
    </p>

    <p class="mb-3">
      {{ t('ttmt.auth.register.success.email_2') }}
    </p>

    <router-link
      class="app-link"
      :to="{ name: 'credential-verification', query: { credential: form.values.credential } }"
    >
      {{ t('ttmt.user_credential_verifications.verify.not_received') }}
    </router-link>
  </div>

  <!--
    v-show is used to keep the form active,
    and do not lose the values
  -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="onSubmit"
  >
    <app-alert
      v-if="route.query.staying_hotel"
      design="info"
      class="mb-5"
    >
      {{ t('ttmt.auth.register.staying_hotel') }}
    </app-alert>

    <form-global-errors class="mb-5" />

    <fieldset :disabled="formSubmitting">
      <form-group
        rules="required|credential"
        name="credential"
        type="email"
        :placeholder="t('ttmt.form.labels.user.credential')"
        :label="false"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <form-group
        rules="required"
        name="password"
        type="password"
        hide-errors
        label-as-placeholder
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.password"
          />
        </template>
      </form-group>

      <form-group
        rules="confirmed:@password|required"
        name="password_confirmation"
        type="password"
        label-as-placeholder
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      />

      <form-group
        rules="required"
        name="privacy_policy"
        type="checkbox"
        flex-direction="row"
        margins="mb-0"
      >
        <template #label>
          <label class="ml-2 font-medium form-label">
            {{ t('ttmt.form.labels.register.privacy_policy.part1') }}
            <span
              class="app-link"
              @click="togglePrivacyPolicy"
            >
              {{ t('ttmt.form.labels.register.privacy_policy.part2') }}
            </span>
          </label>
        </template>
      </form-group>

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="displayPrivacyPolicy"
        class="text-justify h-60 overflow-y-scroll"
        v-html="privacyPolicyL10nContent"
      />

      <form-group
        rules="required"
        name="terms_conditions"
        type="checkbox"
        flex-direction="row"
        margins="mb-0"
      >
        <template #label>
          <label class="ml-2 font-medium form-label">
            {{ t('ttmt.form.labels.register.terms_conditions.part1') }}
            <span
              class="app-link"
              @click="toggleTermsConditions"
            >
              {{ t('ttmt.form.labels.register.terms_conditions.part2') }}
            </span>
          </label>
        </template>
      </form-group>

      <div
        v-if="displayTermsCondition"
        class="text-justify h-60 overflow-y-scroll"
        v-html="termsConditionL10nContent"
      />

      <form-group
        name="communication_consent"
        type="checkbox"
        flex-direction="row"
        margins="my-2"
      >
        <template #label>
          <label class="ml-2 font-medium form-label">
            {{ t('ttmt.form.labels.register.communication_consent') }}
          </label>
        </template>
      </form-group>

      <!-- Link provenance (UTM fields) -->
      <auth-utm-form-fields />

      <app-button
        type="submit"
        width="full"
        :disabled="formSubmitting || invalid"
        :loading="formSubmitting"
        :label="t('ttmt.auth.messages.register')"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
      />
    </fieldset>
  </form>

  <app-separator
    class="my-8"
  />

  <p
    class="font-bold block w-fit mx-auto"
  >
    {{ t('ttmt.auth.messages.have_account') }}
  </p>

  <router-link
    v-slot="{ navigate }"
    :to="{ name: 'login', query: route?.query }"
    class="font-bold text-blue-600"
  >
    <app-button
      width="full"
      :label="t('ttmt.auth.messages.login')"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto mt-6 mb-12"
      @click="navigate"
    />
  </router-link>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { register } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import useLocale from '@shared/hooks/locale'
import usePrivacyPolicy from '@shared/hooks/privacyPolicy'
import useTermsCondition from '@shared/hooks/termsCondition'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AuthHeader from '@app/components/auth/AuthHeader.vue'
import AuthTitle from '@app/components/auth/AuthTitle.vue'
import AuthUtmFormFields from '@shared/components/auth/AuthUtmFormFields.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'

const { t } = useI18n()
const route = useRoute()
const { shortLocale } = useLocale()

// ---------- FORM ----------

const success = ref(false)
const formSubmitting = ref(false)
const formErrors = ref({})

const {
  handleSubmit,
  form,
  invalid,
} = useForm({ additionalErrors: formErrors })

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    // Set the submitted locale
    fieldsToSubmit.locale = shortLocale.value

    const params = {
      data: {
        type: 'users',
        attributes: {
          ...fieldsToSubmit,
          user: route.query.u,
          excursion: route.query.e,
          poi: route.query.p,
          staying_hotel: route.query.staying_hotel,
        },
      },
    }

    register(params)
      .then(() => {
        success.value = true
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- PRIVACY POLICY ----------

const displayPrivacyPolicy = ref(false)

function togglePrivacyPolicy() {
  displayPrivacyPolicy.value = !displayPrivacyPolicy.value
}

const { l10nContent: privacyPolicyL10nContent } = usePrivacyPolicy()

// ---------- TERMS CONDITIONS ----------

const displayTermsCondition = ref(false)

function toggleTermsConditions() {
  displayTermsCondition.value = !displayTermsCondition.value
}

const { l10nContent: termsConditionL10nContent } = useTermsCondition()
</script>
