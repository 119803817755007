<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <app-alert
      v-if="route.query.staying_hotel"
      design="info"
      class="mb-5"
    >
      {{ t('ttmt.auth.register.staying_hotel') }}
    </app-alert>

    <form-global-errors />

    <fieldset :disabled="submitting">
      <form-group
        rules="required|credential"
        name="credential"
        type="email"
        :placeholder="t('ttmt.form.labels.user.credential')"
        :label="false"
        :initial-value="credential"
        :form-control-props="{
          autocomplete: 'username',
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <router-link
        v-if="withCredentialVerificationNotReceived"
        :to="{ name: 'credential-verification', query: { credential: values.credential } }"
        class="app-link w-fit text-sm -mt-2 mb-5 ml-0.5 block"
      >
        {{ t('ttmt.user_credential_verifications.verify.not_received') }}
      </router-link>

      <form-group
        rules="required"
        name="password"
        type="password"
        label-as-placeholder
        :form-control-props="{
          autocomplete: 'current-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      />

      <router-link
        v-if="withPasswordForgotten"
        :to="{ name: 'password-forgotten', query: { credential: values.credential } }"
        class="app-link w-fit text-sm -mt-2 mb-5 ml-0.5 block"
      >
        {{ t('ttmt.auth.messages.reset_password') }}
      </router-link>

      <form-group
        :initial-value="true"
        name="remember"
        type="hidden"
        flex-direction="row"
      />

      <div
        class="mx-4 grid grid-cols-1 gap-2 justify-around"
      >
        <app-button
          type="submit"
          width="full"
          :disabled="submitting"
          :loading="submitting"
          :label="t('ttmt.auth.messages.login')"
          display-classes="flex"
          class="min-w-fit mx-auto mt-4 mb-2"
        />
      </div>
    </fieldset>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import useForm from '@shared/hooks/form/form'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Display password forgotten link or not
  withPasswordForgotten: {
    type: Boolean,
    default: false,
  },
  // Display credential verification link or not
  withCredentialVerificationNotReceived: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'submitted',
])

const route = useRoute()

const credential = route.query.credential

const { t } = useI18n()
const {
  handleSubmit,
  values,
} = useForm(props, { emits })
</script>
