<template>
  <div
    class="w-screen max-w-screen-lg h-52 -mx-4 -mt-2 relative overflow-hidden landscape:bg-center bg-cover flex items-center"
    style="background-image: url('/images/app/auth/bg.jpg');"
  >
    <div
      class="relative w-72 m-auto mt-12"
    >
      <img
        class="w-56 mx-auto"
        alt="logo"
        src="/images/shared/logos/horizontal_white.svg"
      >

      <p class="mt-2 text-white text-lg font-semibold text-center">
        {{ t('ttmt.auth.travel_assistant') }}
      </p>
    </div>

    <!-- Make an inner curve at bottom border -->
    <div
      class="-right-1/10 -left-1/10 -bottom-8 bg-body h-20 absolute rounded-1/2"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
