<template>
  <a
    :href="dynamicUrl"
    class="fill-theme-500 h-10 w-full border-theme-500 border-2 bg-transparent text-theme-500 hover:text-theme-600 py-1 pl-2 pr-4 font-semibold rounded-full text-base whitespace-nowrap inline-flex justify-center items-center duration-300"
  >
    <div class="flex justify-between items-center w-full">
      <!-- eslint-disable vue/no-v-html -->
      <figure
        class="w-8 h-8 p-1 pt-1.5"
        v-html="svgContent"
      />

      {{ label }}
    </div>
  </a>
</template>

<script setup>
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  // URL of link
  url: {
    type: String,
    default: null,
  },
  // Name
  name: {
    type: String,
    default: null,
  },
  // Icon path
  path: {
    type: String,
    default: '',
  },
  // Route's query (if exist)
  query: {
    type: [Object, Array],
    default: () => [],
  },
  // Force label
  forceLabel: {
    type: String,
    default: null,
  },
})

/**
 * URL ref
 */
const dynamicUrl = ref(props.path)

/**
 * Svg file ref
 */
const svgContent = ref()

const label = computed(() => {
  // Force label with props
  if (props.forceLabel) {
    return props.forceLabel
  }

  const base = t('ttmt.auth.messages.continue')
  const networkName = t(`enums.App\\Enums\\Social\\SourceEnum.${props.name}`)

  return `${base} ${networkName}`
})

/**
 * Fetch Svg file
 */
onBeforeMount(() => {
  // Query fetching for traveller folder creation
  const params = new URLSearchParams()

  Object.keys(props.query).forEach((key) => {
    params.append(key, props.query[key])
  })

  dynamicUrl.value = `${props.url}?${params.toString()}`

  fetch(`/images/shared/svg_icons/${props?.path}${props.name}.svg`)
    .then((response) => {
      if (response.status === 200) {
        response.text()
          .then((text) => {
            svgContent.value = text
          })
      }
    })
})
</script>
